import {NgModule} from '@angular/core'
import {CommonModule} from '@angular/common'
import {TranslateNestedPipe} from './translate-nested.pipe'
import {FullNamePipe} from './full-name.pipe'
import {SafePipe} from './safe.pipe'
import {CompetencyListPipe} from './competency-list.pipe'
import {AgencyNamePipe} from './agency-name.pipe'
import {MailToPipe} from './mailTo.pipe'
import {TelPipe} from './tel.pipe'
import {InitialsPipe} from './initials.pipe'
import {AbsoluteUrlPipe} from './absolute-url.pipe'
import {RappenToFrancPipe} from './rappen-to-franc.pipe'
import {SalutationPipe} from './salutation.pipe'
import {AsFormControlPipe} from './as-form-control.pipe'
import {AsFormGroupPipe} from './as-form-group.pipe'
import {UrlFromFormControl} from './url-from-form-control'
import {SwissPhoneNumberPipe} from './swiss-phone-number.pipe'
import {TruncatePipe} from './truncate.pipe'
import {PrioritizedUserRolePipe} from './prioritized-user-role.pipe'
import {NullToUndefinedPipe} from './nullToUndefined.pipe'
import {NullToEmptyStringPipe} from './null-to-empty-string.pipe'
import {MonthPipe} from './month.pipe'

/**
 * @deprecated This module will be removed soon, and pipes will be converted to standalone to favour tree shaking
 * Do not add new pipes to this module
 */
@NgModule({
  declarations: [
    TranslateNestedPipe,
    FullNamePipe,
    SafePipe,
    CompetencyListPipe,
    AgencyNamePipe,
    MailToPipe,
    TelPipe,
    InitialsPipe,
    AbsoluteUrlPipe,
    RappenToFrancPipe,
    SalutationPipe,
    AsFormControlPipe,
    AsFormGroupPipe,
    UrlFromFormControl,
    SwissPhoneNumberPipe,
    TruncatePipe,
    PrioritizedUserRolePipe,
    NullToUndefinedPipe,
    NullToEmptyStringPipe,
    MonthPipe,
  ],
  exports: [
    TranslateNestedPipe,
    FullNamePipe,
    SafePipe,
    CompetencyListPipe,
    AgencyNamePipe,
    MailToPipe,
    TelPipe,
    InitialsPipe,
    AbsoluteUrlPipe,
    RappenToFrancPipe,
    SalutationPipe,
    AsFormControlPipe,
    AsFormGroupPipe,
    UrlFromFormControl,
    SwissPhoneNumberPipe,
    TruncatePipe,
    PrioritizedUserRolePipe,
    NullToUndefinedPipe,
    NullToEmptyStringPipe,
    MonthPipe,
  ],
  imports: [CommonModule],
})
export class PipesModule {}
